import React from 'react'
import { useTranslation } from 'react-i18next';

interface TFormInputs {
  error: any;
}

const Error: React.FC<TFormInputs> = ({ error }) => {
  const { t } = useTranslation();
  switch (error?.type) {
    case 'required':
      return <div className="error-message">{t('contact.form.error.empty')}</div>;
    case 'pattern':
      switch (error?.message) {
        case 'patternPhone':
          return <div className="error-message">{t('contact.form.error.phone')}</div>;
        case 'patternLinkedin':
          return <div className="error-message">{t('contact.form.error.linkedin')}</div>;
        case 'Mail':
          return <div className="error-message">{t('contact.form.error.mail')}</div>;
        default:
          return null
      };
    case 'minLength':
      switch (error?.message) {
        case 'minLengthPhone':
          return <div className="error-message">{t('contact.form.error.minLengthPhone')}</div>;
        case 'minLengthName':
          return <div className="error-message">{t('contact.form.error.minLengthName')}</div>;
        case 'minLengthSurname':
          return <div className="error-message">{t('contact.form.error.minLengthName')}</div>;
        case 'minLengthDescription':
          return <div className="error-message">{t('contact.form.error.minLengthDescription')}</div>;
        default:
          return null
      };
    case 'validate':

        switch (error?.message) {
          case 'fileSizeMax':
            return <div className="error-message">{t('contact.form.error.fileSizeMax')}</div>;
            case 'registeredEmail' :
            return <div className="error-message">{t('newsletter.join.fetchData.succesEmail')}</div>;
          case 'existingEmail' : 
            return <div className="error-message">{t('newsletter.join.fetchData.emailInUse')}</div>;
          default:
            return null
        }
  }
  return (
    <>
    </>
  )
}

export default Error