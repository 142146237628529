import api from 'apiConfig/api';
import { useEffect, useState } from 'react';

interface ApiResponse<T = any> {
  data?: T | null;
  error: Error | null;
  isLoading: boolean;
}

const useGetApi = <T = any>(url: string): ApiResponse<T> => {

  const [data, setData] = useState<T>();
  const [error, setError] = useState<Error | null>(null);

  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await api.get<T>(url);
        setData(response.data);
      }
      catch (error) {
        if (error instanceof Error) {
          setError(error)
        }
      }
      finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, setLoading]);


  return {
    isLoading,
    data,
    error
  };
};

export default useGetApi;
