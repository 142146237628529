import React from "react";

// Icons
import { ReactComponent as Facebook } from 'assets/icons/social-facebook.svg';
import { ReactComponent as Instagram } from 'assets/icons/social-instagram.svg';
import { ReactComponent as Linkedin } from 'assets/icons/social-linkedin.svg';
import { ReactComponent as Twitter } from 'assets/icons/social-twitter.svg';
import { ReactComponent as Youtube } from "assets/icons/social-youtube.svg";

// Styles
import './socials.scss';

const Socials = (prop: any) => {

    const setHeigth = {
        height: prop.height,
        justifyContent: prop.justifyContent
    };

    return (
        <div className="socials" style={setHeigth}>
            <a href="https://www.instagram.com/neocomplexx/" target="_blank" rel="noreferrer">
                <Instagram className="socials_icons" />
            </a>
            <a href="https://www.facebook.com/neocomplexx/" target="_blank" rel="noreferrer">
                <Facebook className="socials_icons" />
            </a>
            <a href="https://www.linkedin.com/company/neocomplexx" target="_blank" rel="noreferrer">
                <Linkedin className="socials_icons" />
            </a>
            <a href="https://twitter.com/neocomplexx" target="_blank" rel="noreferrer">
                <Twitter className="socials_icons" />
            </a>
            <a href="https://www.youtube.com/@neocomplexx3428" target="_blank" rel="noreferrer">
                <Youtube className="socials_icons" />
            </a>
        </div>
    );
};

export default Socials;