import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";


// Styles
import './infoCareer.scss';

type Props = {
    title: string;
    description: string;
    workTask: [];
    workChallenge: [];
    workCondition: [];
    proposal: string;

};

const InfoCareer: React.FC<Props> = ({ title, description, workTask, workChallenge, workCondition, proposal, }) => {
    const { t } = useTranslation();
    const [link, setLink] = useState("/jobs/cv");

    useEffect(() => {
        const jobTitle = title;
        const fullLink = jobTitle.replace(/\s+/g, '-');
        setLink(`/jobs/${fullLink}`);
    }, [t, title]);

    return (
        <div className="infoCareer">
            <h1 className="infoCareer_title"> {title} </h1>
            <p className="infoCareer_description"> {description} </p>
            <p className="infoCareer_subtitle"> {t('careersPage.current.junior.content.tasks')}</p>
            <ul className="infoCareer_list">
                {workTask.map((task, index) => (
                    <li key={index} className="infoCareer_bullet"> {task} </li>
                ))}
            </ul>
            <p className="infoCareer_subtitle"> {t('careersPage.current.junior.content.challenges')}</p>
            <ul>
                {workChallenge.map((challenge, index) => (
                    <li className="infoCareer_bullet" key={index+1}> {challenge}</li>
                ))}
            </ul>
            <p className="infoCareer_subtitle">{t('careersPage.current.junior.content.conditions')} </p>
            <ul>
                {workCondition.map((condition, index) => (
                    <li className="infoCareer_bullet" key={index+2}>{condition}</li>
                ))}
            </ul>
            <p className="infoCareer_subtitle"> {t('careersPage.current.junior.content.proposalTitle')}</p>
            <p className="infoCareer_description">{proposal}</p>
            <NavLink className="submit-button" to={`${link}`}><button aria-label={t('careersPage.apply') || ''} >{t('careersPage.apply')}</button>
            </NavLink>
        </div>
    );
};

export default InfoCareer;