import axios from 'axios';
import { getApiBaseUrl } from './config';

const api = axios.create({
  baseURL: `${getApiBaseUrl()}`, // Base URL of your API staging

  
  timeout: 5000, // Maximum timeout for requests in milliseconds
  headers: {
    'Content-Type': 'application/json', // Content-Type for requests
    // Other common headers, such as authentication tokens, can be added here.
  },
});

// Interceptor to modify request headers
api.interceptors.request.use(config => {
  // If the request contains a FormData object, the content type is set to 'multipart/form-data'.
  if (config.data instanceof FormData) {
    config.headers['Content-Type'] = 'multipart/form-data';
  } else {
    //  Otherwise, the content type is set to 'application/json'
    config.headers['Content-Type'] = 'application/json';
  }
  return config;
});


export default api;
