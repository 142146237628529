import React from "react";
import { useTranslation } from 'react-i18next';

// Icons
import { ReactComponent as LocationIcon } from 'assets/icons/icon-location.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/icon-time.svg';
// Styles
import './cardCareer.scss';


type Props = {
    title: string;
    location: string;
    time: string;
    postedOn: string;

};

const CardCareer: React.FC<Props> = ({ title, location, time, postedOn }) => {
    const date = new Date(postedOn);
    const day = date.getUTCDate();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');

    const dateCard = `${day}/${month}`;
    const { t } = useTranslation();

    return (
        <>
            <p className="cardCareer_title"> {title}</p>
            <span className="cardCareer_location">
                <LocationIcon className="cardCareer_location--icon" />
                <p> {location} </p>
            </span>
            <span className="cardCareer_time">
                <TimeIcon className="cardCareer_time--icon" />
                <p> {time} </p>
            </span>
            <p className="cardCareer_postedOn"> {t(`careersPage.card.postOn`)} {dateCard}</p>
        </>
    );
};

export default CardCareer;