import AdminNavbar from "features/Admin/adminNavbar/adminNavbar";
import AdminDetails from "../adminDetails/adminDetails";
import { Route, Routes } from "react-router-dom";
import UploadProposal from "../uploadProposal/uploadProposal";

import "./adminHome.scss";
const AdminHome = () => {
  return (
    <>
      <AdminNavbar />
      <div className="admin-content">
        <Routes>
          <Route path="/" element={<AdminDetails />} />
          <Route path="/uploadProposal" element={<UploadProposal />} />
        </Routes>
      </div>
    </>
  );
};

export default AdminHome;
