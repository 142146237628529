import React, { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

//Style
import '../titleCareer/titleCareer.scss'

const TitleCareer = () => {

    const { t } = useTranslation();
    const tFunc = useCallback(() => t, [t]);
    const [mark, setMark] = useState(t('careersPage.mark.first'));
    const [title, setTitle] = useState('');


    const cyclePhrases = useCallback(() => {
        const phrases = [
            tFunc()('careersPage.mark.first'),
            tFunc()('careersPage.mark.second'),
            tFunc()('careersPage.mark.third')
        ];

        let index = 1;
        const interval = setInterval(() => {
            if (index >= phrases.length) {
                index = 0;
            }
            setMark(phrases[index]);

            if (index === phrases.length - 1) {
                setTitle(`${t('careersPage.title2')}`);
            } else {
                setTitle('');
            }

            index++;
        }, 2500);

        return () => clearInterval(interval);
    }, [t, tFunc]);

    useEffect(() => {
        const cleanup = cyclePhrases();
        return cleanup;
    }, [cyclePhrases]);


    return (
        <h1 className='titleCareers'> {t('careersPage.title1')} <mark>{mark}</mark> {title}</h1>
    )
}

export default TitleCareer;