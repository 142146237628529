import { Modal } from "@mui/base";
import useAuthorizedRequest from "Hooks/useAuthorizedRequest";

import 'components/modal.scss'


const ModalComponent = ({ open, setOpen, id }: any) => {
    const { authorizedRequest } = useAuthorizedRequest<void>(`api/workProposals/delete/${id}`);
    const handleClose = () => setOpen(false);

    const handleDeleteClick = async () => {
        if (id !== null) {
            try {
                await authorizedRequest();
                console.log('Propuesta eliminada con éxito');
                handleClose();
            } catch (error) {
                console.error('Error al eliminar la propuesta:', error);
            }
        }
    };
 
    return (
        <div  >
            <Modal
                open={open}
                onClose={handleClose}
                slots={{}}
            >
                <div className="modalComponent">
                    <div className="modalComponent-box">
                        <h2 id="unstyled-modal-title" className="modalComponent-box__title">Eliminar propuesta</h2>
                        <p id="unstyled-modal-description" className="modalComponent-box__text">¿Desea eliminar la propuesta?</p>
                        <div className="modalComponent-box_btn">
                            <button className="modalComponent-box_btn-style" onClick={() => { handleDeleteClick() }}>
                                Aceptar
                            </button>
                            <button className="modalComponent-box_btn-style" onClick={() => {
                                handleClose()
                            }}>
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ModalComponent
