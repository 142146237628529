import React from 'react';
import { useSearch } from 'Context/searchContext'; 
import { SearchResult } from 'Context/searchContext';
import "./search.scss";

function Search() {
  const { searchTerm, setSearchTerm, performSearch, searchResults, navigateToResult } = useSearch();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    performSearch(value);
  };

  const handleResultClick = (result: SearchResult) => {
    navigateToResult(result);  
  };

   const handleInputClick = (e : any) => {
    e.stopPropagation();
  };

  return (
    <div className="boxSearch">
      <div className="search-dropdown">  
        <input 
          id='search'
          className='inp'
          type="text" 
          autoComplete='off'
          value={searchTerm}
          onChange={handleSearch}
          onClick={handleInputClick}
        />
      <div className="result">
        {searchTerm ? (
          searchResults.length > 0 ? (
            <ul>
              {searchResults.map((result, index) => (
                <li key={index} onClick={() => handleResultClick(result)}>{result.content.charAt(0).toUpperCase() + result.content.slice(1)}<hr className='hr'></hr></li>
              ))}
            </ul>
          ) : (
            <p>No results</p>
          )
        ) : null}
      </div>
      </div>
    </div>
  )
}

export default Search;










