import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from 'assets/translations/en.json';
import spTranslation from 'assets/translations/sp.json';
import enLegal from 'assets/translations/enLegal.json';
import spLegal from 'assets/translations/spLegal.json';
import enNewsletter from 'assets/translations/enNewsletter.json';
import spNewsletter from 'assets/translations/spNewsletter.json';
import enJobForm from 'assets/translations/enJobForm.json';
import spJobForm from 'assets/translations/spJobForm.json';
import enRols from 'assets/translations/enRols.json';
import spRols from 'assets/translations/spRols.json';
import enCareers from 'assets/translations/enCareers.json';
import spCareers from 'assets/translations/spCareers.json';
import enLogin from 'assets/translations/enLogin.json';
import spLogin from 'assets/translations/spLogin.json';


i18n
    // detect user language
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    .init({
        debug: true,
        fallbackLng: 'sp',
        react: { useSuspense: false },
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: {
                    ...enTranslation,
                    ...enLegal,
                    ...enNewsletter,
                    ...enJobForm,
                    ...enRols,
                    ...enCareers,
                    ...enLogin
                }
            },
            sp: {
                translation: {
                    ...spTranslation,
                    ...spLegal,
                    ...spNewsletter,
                    ...spJobForm,
                    ...spRols,
                    ...spCareers,
                    ...spLogin
                }
            }
        }
    });

export default i18n;