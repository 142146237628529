import React,  { useEffect, useState } from "react";

// Components
import Navbar from 'features/header/navbar';

// Assets

// Styles
import './header.scss';

const Header = () => {
  const [isMobile, setMobile] = useState(window.innerWidth <= 599);


  

  const handleMobileChange = (isMobile:any) => {
    setMobile(isMobile);
  };

  const updateMedia = () => {
    setMobile(window.innerWidth <= 599);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
    
  }, []);

  return (
    <div data-testid="header" className="header-neo">
      <header className="header">
        <div className="header_nav">
          <Navbar onChange={handleMobileChange} isMobile={isMobile}/> 
        </div>
      </header>
    </div>
  );
};

export default Header;