import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Assets
import devJpg from 'assets/images/rols-dev.jpg';
import devWebp from 'assets/images/rols-dev.webp';
import designJpg from 'assets/images/rols-design.jpg';
import designWebp from 'assets/images/rols-design.webp';

// Styles
import './rolsPage.scss';

import { getImagePath } from 'Context/imgSupports';
import Loading from 'components/loading'

const RolsPage = () => {

    const { t } = useTranslation();

    const [isMobile, setMobile] = useState(window.innerWidth < 599);
    const [isLoading, setIsLoading] = useState(true);

    const updateMedia = () => {
        setMobile(window.innerWidth < 599);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        setIsLoading(false);
        return () => window.removeEventListener("resize", updateMedia);

    }, []);

    const devPath = getImagePath(devWebp, devJpg);
    const designPath = getImagePath(designWebp, designJpg);


    if (isLoading) {
        return (
            <div >
                <Loading />
            </div>
        );
    }

    return (
        <>
            {isMobile ? // Mobile

                <div className='rols'>
                    <h1 className='rols_title'>{t('rols.title')} <mark>{t("rols.mark")}</mark></h1>
                    <div className='rols_row first'>
                        <span className='container col_first'>
                            <div className='rols_row--card backend'>
                                <h2>
                                    {t("rols.card.backend.title")} <br />
                                    <mark>{t("rols.card.backend.subtitle")}</mark>
                                </h2>
                                <p>
                                    {t("rols.card.backend.description")}
                                </p>
                            </div>
                            <div className='rols_row--card transparent essencial'>
                                <h2>
                                    {t("rols.card.essencial.title")} <br />
                                    <mark>{t("rols.card.essencial.subtitle")}</mark>
                                </h2>
                            </div>
                        </span>
                        <span className='container col_second'>
                            <div className='rols_row--card logic'>
                                <h2>
                                    {t("rols.card.logic.title")} <br />
                                    <mark>{t("rols.card.logic.subtitle")}</mark>
                                </h2>
                            </div>
                            <div className='rols_row--card image dev-image'>
                                <img src={devPath} alt="a laptop with a code editor open" />
                            </div>
                        </span>
                    </div>
                    <div className='rols_row second'>
                        <div className='rols_row--card frontend'>
                            <h2>
                                {t("rols.card.frontend.title")} <br />
                                <mark>{t("rols.card.frontend.subtitle")}</mark>
                            </h2>
                            <p>
                                {t("rols.card.frontend.description")}
                            </p>
                        </div>
                        <span className='container col_third'>
                            <div className='rols_row--card transparent community'>
                                <h2>
                                    {t("rols.card.community.title")} <br />
                                    <mark>{t("rols.card.community.subtitle")}</mark>
                                </h2>
                            </div>
                            <div className='rols_row--card transparent minds'>
                                <h2>
                                    {t("rols.card.minds.title")} <br />
                                    <mark>{t("rols.card.minds.subtitle")}</mark>
                                </h2>
                            </div>
                        </span>
                    </div>
                    <div className='rols_row second'>
                        <div className='rols_row--card product'>
                            <h2>
                                {t("rols.card.product.title")} <br />
                                <mark>{t("rols.card.product.subtitle")}</mark>
                            </h2>
                            <p>
                                {t("rols.card.product.description1")} <br />
                                {t("rols.card.product.description2")}
                            </p>
                        </div>
                    </div>
                    <div className='rols_row third'>
                        <span className='container col_fourth'>
                            <div className='rols_row--card transparent commited'>
                                <h2>
                                    {t("rols.card.commited.title")} <br />
                                    <mark>{t("rols.card.commited.subtitle")}</mark>
                                </h2>
                            </div>
                            <div className='rols_row--card image design-image'>
                                <img src={designPath} alt="designer choosing a hue from a colour wheel" />
                            </div>
                        </span>
                        <div className='rols_row--card devops'>
                            <h2>
                                {t("rols.card.devops.title")} <br />
                                <mark>{t("rols.card.devops.subtitle")}</mark>
                            </h2>
                            <p>
                                {t("rols.card.devops.description")}
                            </p>
                        </div>

                    </div>
                </div>

                : // Desktop

                <div className='rols'>
                    <h1 className='rols_title'>{t("rols.title")} <mark>{t("rols.mark")}</mark></h1>
                    <div className='rols_row first'>
                        <span className='container col_first'>
                            <div className='rols_row--card backend'>
                                <h2>
                                    {t("rols.card.backend.title")} <br />
                                    <mark>{t("rols.card.backend.subtitle")}</mark>
                                </h2>
                                <p>
                                    {t("rols.card.backend.description")}
                                </p>
                            </div>
                            <div className='rols_row--card transparent essencial'>
                                <h2>
                                    {t("rols.card.essencial.title")} <br />
                                    <mark>{t("rols.card.essencial.subtitle")}</mark>
                                </h2>
                            </div>
                        </span>
                        <span className='container col_second'>
                            <div className='rols_row--card logic'>
                                <h2>
                                    {t("rols.card.logic.title")} <br />
                                    <mark>{t("rols.card.logic.subtitle")}</mark>
                                </h2>
                            </div>
                            <div className='rols_row--card image dev-image'>
                                <img src={devPath} alt="a laptop with a code editor open" />
                            </div>
                        </span>
                        <span className='container col_third'>
                            <div className='rols_row--card transparent community'>
                                <h2>
                                    {t("rols.card.community.title")} <br />
                                    <mark>{t("rols.card.community.subtitle")}</mark>
                                </h2>
                            </div>
                            <div className='rols_row--card frontend'>
                                <h2>
                                    {t("rols.card.frontend.title")} <br />
                                    <mark>{t("rols.card.frontend.subtitle")}</mark>
                                </h2>
                                <p>
                                    {t("rols.card.frontend.description")}
                                </p>
                            </div>
                        </span>
                    </div>
                    <div className='rols_row second'>
                        <div className='rols_row--card product'>
                            <h2>
                                {t("rols.card.product.title")} <br />
                                <mark>{t("rols.card.product.subtitle")}</mark>
                            </h2>
                            <p>
                                {t("rols.card.product.description1")} <br />
                                {t("rols.card.product.description2")}
                            </p>
                        </div>
                        <div className='rols_row--card transparent minds'>
                            <h2>
                                {t("rols.card.minds.title")} <br />
                                <mark> {t("rols.card.minds.subtitle")}</mark>
                            </h2>
                        </div>
                    </div>
                    <div className='rols_row third'>
                        <div className='rols_row--card transparent commited'>
                            <h2>
                                {t("rols.card.commited.title")} <br />
                                <mark>{t("rols.card.commited.subtitle")}</mark>
                            </h2>
                        </div>
                        <div className='rols_row--card devops'>
                            <h2>
                                {t("rols.card.devops.title")} <br />
                                <mark>{t("rols.card.devops.subtitle")}</mark>
                            </h2>
                            <p>
                                {t("rols.card.devops.description")}
                            </p>
                        </div>
                        <div className='rols_row--card image design-image'>
                            <img src={designPath} alt="designer choosing a hue from a colour wheel" />
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default RolsPage;