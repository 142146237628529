import api from 'apiConfig/api';
import { useState } from 'react';
import { useAppSelector } from './useRedux';


export interface ApiResponse<T = any> {
  data: T | undefined;
  error: Error | null;
  isLoading: boolean;
  authorizedRequest: ( postData: T) => Promise<T> | Promise<void> | Promise<unknown> | Promise<Error>;
}

const useAuthorizedRequest = <T = any>(url: string): ApiResponse<T> => {

  const [data, setData] = useState<T>();
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const tokenState = useAppSelector(state => state.auth.token)
  
  // PostAuthorizedRequest
  const authorizedRequest = async (postData: T) => {
    setIsLoading(true);

    try {
        const response = await api.post(url, postData, {
            headers: {
              token: `${tokenState}`,
            },
          });
    
      setData(response.data);
    } 
    catch (error: unknown) {
      if (error instanceof Error) {
        setError(error);
      } else {
        setError(new Error('An unknown error occurred.'));
      }
    } 
    finally {
      setIsLoading(false);
    }
  };

  return {
    data, 
    error, 
    isLoading, 
    authorizedRequest
    };
};

export default useAuthorizedRequest;

