import { useEffect, useState } from "react";
import useGetApi from "Hooks/useGetApi";

import Loading from "components/loading";
import CareersPage from "./careersPage";
import NoInfoCareer from "./noInfoCareer/noInfoCareer";
import { useAppDispatch } from "Hooks/useRedux";
import { getProposals } from "redux/proposalsSlice";

const ContainerCareersPage = () => {
  const { data } = useGetApi("api/workProposals/all");
  const [loading, setLoading] = useState(!data);
  const dispatch = useAppDispatch();
  //data
  useEffect(() => {
    setLoading(!data || data.length === 0);
    dispatch(getProposals({ data }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return loading ? (
    <div className="careersPage_load">
      <Loading />
    </div>
  ) : data.length !== 0 ? (
    <CareersPage />
  ) : (
    <NoInfoCareer />
  );
};

export default ContainerCareersPage;
