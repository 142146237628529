import Select, { SelectProps } from "@mui/base/Select";
import React from "react";
import { Option, SelectOption } from "@mui/base";
import { useFormContext } from "react-hook-form";

type SelectFormProps = {
  options: any;
  name: string;
  placeholder?: string;
  register?: any;
  errors?: any;
};

const CustomSelect = React.forwardRef<HTMLButtonElement, SelectProps<number, false>>((props, ref) => {
  const slots: SelectProps<number, false>["slots"] = { ...props.slots };
  return (
    <Select {...props} slots={slots} ref={ref} aria-label={"Select" + props.name}
    />
  );
});

const SelectForm: React.FC<SelectFormProps> = ({
    options,
    placeholder,
    register,
    name,
    errors,
}) => {
  const {setValue } = useFormContext();

  function renderValue(option: SelectOption<number> | null) {
    if (option == null) {
      return <p>{placeholder}</p>;
    }
    return <p>{option.value}</p>;
  }
  return (
   
      <CustomSelect
        className={errors.location ? "invalid select" : "select valid"}
        renderValue={renderValue}
        listboxId="list"
        {...register(name, { required: true })}
      >
        {options.map((option: any, index: number) => (
          <Option className="option" key={index} value={option} onClick={() => { setValue(name, option,{shouldValidate: true,shouldDirty: true, shouldTouch: true})}} >
            {option}
          </Option>
        ))}
      </CustomSelect>
  
  );
};

export default SelectForm;
