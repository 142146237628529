import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SelectLanguages from "./languages";
import SelectSearch from "./search";
import ClickAwayListener from "@mui/base/ClickAwayListener";

// Assets
import Typo from "assets/neo-typo.png";
import { ReactComponent as Language } from "assets/icons/icon-language.svg";
import { ReactComponent as Search } from "assets/icons/icon-search.svg";

// Styles
import "./navbar.scss";
import { useAppSelector } from "Hooks/useRedux";

const windowHeigth = window.innerHeight;
type NavbarProps = {
  onChange: any;
  isMobile: any;
};

const Navbar: React.FC<NavbarProps> = ({ onChange, isMobile }) => {
  const goTo = useNavigate();
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [openLanguage, setOpenLanguage] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  const handleOpenLanguage = () => {
    setOpenLanguage(!openLanguage);
    setOpenSearch(false);
  };

  const handleOpenSearch = () => {
    setOpenSearch(!openSearch);
    setOpenLanguage(false);
  };

  const handleClickAway = () => {
    setOpenSearch(false);
  };

  const handleClickAwayLenguage = () => {
    setOpenLanguage(false);
  };

  // To use pass an Id of an element and adjust the offsetFactor to the desired value,
  // bigger number means less offset, so the element will be closer to the top of the screen.
  function scrollToElement(
    elementId: string,
    offsetFactor: number,
    windowHeigth: number
  ): void {
    const offset = windowHeigth / offsetFactor;

    setTimeout(() => {
      let element = document.getElementById(elementId);

      if (!element) {
        goTo("/");
        setTimeout(() => {
          element = document.getElementById(elementId);
          if (!element) {
            return;
          }
          window.scrollTo({
            top: element.offsetTop - offset,
            behavior: "smooth",
          });
          return;
        }, 10);
      } else {
        window.scrollTo({
          top: element.offsetTop - offset,
          behavior: "smooth",
        });
      }
    }, 50);
  }
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      {isMobile ? (
        <div>
          <div className="header_language">
            <ClickAwayListener onClickAway={handleClickAwayLenguage}>
              <button
                className="header_language--button"
                onClick={handleOpenLanguage}
                aria-label={t("lenguage.title") || ""}
              >
                <Language />
                {/* <img src={LanguageNeo} alt="" /> */}
                {openLanguage ? <SelectLanguages /> : null}
              </button>
            </ClickAwayListener>
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={handleClickAway}
            >
              <button
                className="header_search--button"
                onClick={handleOpenSearch}
              >
                <Search />
                {openSearch ? <SelectSearch /> : null}
              </button>
            </ClickAwayListener>
          </div>
          <div
            className={`hamburger ${menuOpen ? "is-active" : ""}`}
            onClick={toggleMenu}
          >
            <div className="_layer -top"></div>
            <div className="_layer -mid"></div>
            <div className="_layer -bottom"></div>
          </div>
          {menuOpen && (
            <div className="menu-overlay" onClick={toggleMenu}></div>
          )}
          <nav className={`navigation ${menuOpen ? "" : "menuppal"}`}>
            <ul className="navigation_sections" onClick={toggleMenu}>
              <li onClick={() => scrollToElement("services", 10, windowHeigth)}>
                <NavLink to="/">{t("header.services")}</NavLink>
              </li>
              <li onClick={() => scrollToElement("projects", 7, windowHeigth)}>
                <NavLink to="/">{t("header.proyects")}</NavLink>
              </li>
              <li onClick={() => scrollToElement("approach", 7, windowHeigth)}>
                <NavLink to="/">{t("header.approach")}</NavLink>
              </li>
              <li onClick={() => scrollToElement("culture", 10, windowHeigth)}>
                <NavLink to="/">{t("header.culture")}</NavLink>
              </li>
              <li>
                <NavLink to="/careers">{t("header.careers")}</NavLink>
              </li>
              <li onClick={() => scrollToElement("contact", 100, windowHeigth)}>
                <NavLink to="/">{t("header.contact")}</NavLink>
              </li>
              {isAuthenticated ? (
                <li>
                  <NavLink className="btnLogin" to="/adminHome">
                    Home
                  </NavLink>
                </li>
              ) : (
                <li>
                  <NavLink to="/login">
                    <button className="btnLogin">{t("header.login")}</button>
                  </NavLink>
                </li>
              )}
            </ul>
          </nav>
          <div className="header_brand">
            <NavLink to="/" className="header_brand--logo">
              <img src={Typo} alt="neocomplexx-icon" />
            </NavLink>
          </div>
        </div>
      ) : (
        <>
          <nav className="navigation">
            <ul className="navigation_sections">
              <li onClick={() => scrollToElement("services", 10, windowHeigth)}>
                <NavLink to="/">{t("header.services")}</NavLink>
              </li>
              <li onClick={() => scrollToElement("projects", 7, windowHeigth)}>
                <NavLink to="/">{t("header.proyects")}</NavLink>
              </li>
              <li onClick={() => scrollToElement("approach", 7, windowHeigth)}>
                <NavLink to="/">{t("header.approach")}</NavLink>
              </li>
              <li onClick={() => scrollToElement("culture", 10, windowHeigth)}>
                <NavLink to="/">{t("header.culture")}</NavLink>
              </li>

              <div className="header_brand">
                <NavLink to="/" className="header_brand--logo">
                  <img src={Typo} alt="neocomplexx-icon" />
                </NavLink>
              </div>
              <li>
                <NavLink to="/careers">{t("header.careers")}</NavLink>
              </li>
              <li onClick={() => scrollToElement("contact", 100, windowHeigth)}>
                <NavLink to="/">{t("header.contact")} </NavLink>
              </li>
              {isAuthenticated ? (
                <li>
                  <NavLink className="btnLogin" to="/adminHome">
                    Home
                  </NavLink>
                </li>
              ) : (
                <li>
                  <NavLink to="/login">
                    <button className="btnLogin">{t("header.login")}</button>
                  </NavLink>
                </li>
              )}
              {/* <div className="header_language"> */}
              <li className="icons">
                <ClickAwayListener onClickAway={handleClickAwayLenguage}>
                  <button
                    className="header_language--button"
                    onClick={handleOpenLanguage}
                    aria-label={t("lenguage.title") || ""}
                  >
                    <Language />
                    {/* <img src={LanguageNeo} alt="" /> */}
                    {openLanguage ? <SelectLanguages /> : null}
                  </button>
                </ClickAwayListener>
              </li>
              <li className="icons">
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={handleClickAway}
                >
                  <button
                    className="header_search--button"
                    onClick={handleOpenSearch}
                  >
                    <Search />
                    {openSearch ? <SelectSearch /> : null}
                  </button>
                </ClickAwayListener>
              </li>
              {/* </div> */}
            </ul>
          </nav>
        </>
      )}
    </>
  );
};

export default Navbar;
