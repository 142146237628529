import React from 'react';
import i18n from 'i18n';
import { withTranslation } from 'react-i18next';

// Styles
import './languages.scss';

const SelectLanguage = ({ t }: { t: any }) => {
    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    }

    return (
        <div className='box'>
            <ul className='language-dropdown'>
                <li onClick={() => changeLanguage('sp')}>{t('language.spanish')}</li>
                <div className="line"></div>
                <li onClick={() => changeLanguage('en')}>{t('language.english')}</li>
            </ul>
        </div>
    )
}

export default withTranslation()(SelectLanguage);