import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ApiMessage, TProposal } from "types/types";
import useAuthorizedRequest from "Hooks/useAuthorizedRequest";

import SelectForm from "components/inputsForm/select";
import { NavLink } from "react-router-dom";
import Error from "components/inputsForm/error";

import "./uploadProposal.scss";

const UploadProposal = () => {
  const { data, authorizedRequest: sendForm } = useAuthorizedRequest<
    TProposal | ApiMessage
  >("api/workProposals/create");

  const methods = useForm<TProposal>({});
  const {
    register,
    formState: { errors },
  } = methods;

  const location = [
    "Bahía Blanca",
    "Ingeniero White",
    "Punta Alta",
    "Monte Hermoso",
  ];
  const workModel = [
    "Presencial full time",
    "Presencial part time",
    "Remoto full time",
    "Remoto part time",
    "A convenir",
  ];

  const onSubmit: SubmitHandler<TProposal> = (info: any) => {
    info["workTasks"] = info["workTasks"].split("\n");
    info["workChallenges"] = info["workChallenges"].split("\n");
    info["workConditions"] = info["workConditions"].split("\n");
    sendForm(info);
  };
  const minLength = {
    value: 50,
    message: 'minLengthDescription',
}


  if (data) {
    return (
      <div className="successfully">
        <div className="successfully__box">
          <span className="successfully__title">
            Tu búsqueda se cargo <mark>correctamente</mark>
          </span>
          <span className="successfully__text">
            Podrás visualizarla en los próximos minutos en
            <NavLink to="/careers" className="successfully__link">
              <mark> Carreras</mark>
            </NavLink>
          </span>
        </div>
      </div>
    );
  }

  return (
    <>
      <FormProvider {...methods}>
        <div className="uploadProposal">
          <h2>
            Crear
            <mark> búsqueda</mark>
          </h2>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="uploadProposal_form"
          >
            <div className="formbox1">
              <label htmlFor="title">Puesto</label>
              <input
                className="inputTitle"
                type="text"
                {...register("title", { required: true })}
              />
              <span className="conditions">
                <Error error={errors.title} />
              </span>
            </div>
            <div className="formbox1">
              <label htmlFor="location">Ubicación</label>
              <SelectForm
                register={register}
                options={location}
                name={"location"}
                errors={errors}
              />
              <span className="conditions">
                <Error error={errors.location} />
              </span>
            </div>
            <div className="formbox1">
              <label htmlFor="workModel">Modalidad</label>
              <SelectForm
                register={register}
                options={workModel}
                name={"workModel"}
                errors={errors}
              />
              <span className="conditions">
                <Error error={errors.workModel} />
              </span>
            </div>
            <div className="formbox2">
              <label htmlFor="description">Descripción</label>
              <textarea
                id=""
                cols={100}
                rows={3}
                {...register("description", { required: true, minLength })}
              ></textarea>
              <span className="conditions">
                Texto sin separaciones
                <Error error={errors.description} />
              </span>
            </div>
            <div className="formbox2">
              <label htmlFor="workTasks">Tareas</label>
              <textarea
                id=""
                cols={100}
                rows={3}
                {...register("workTasks", { required: true })}
              ></textarea>
              <span className="conditions">
                Lista separada por enter
                <Error error={errors.workTasks} />
              </span>
            </div>
            <div className="formbox2">
              <label htmlFor="workChallenges">Desafíos</label>
              <textarea
                id=""
                cols={100}
                rows={3}
                {...register("workChallenges", { required: true })}
              ></textarea>
              <span className="conditions">
                Lista separada por enter
                <Error error={errors.workChallenges} />
              </span>
            </div>
            <div className="formbox2">
              <label htmlFor="workConditions">Condiciones</label>
              <textarea
                id=""
                cols={100}
                rows={3}
                {...register("workConditions", { required: true })}
              ></textarea>
              <span className="conditions">
                Lista separada por enter
                <Error error={errors.workConditions} />
              </span>
            </div>
            <div className="formbox2">
              <label htmlFor="proposals">Propuestas</label>
              <textarea
                id=""
                cols={100}
                rows={3}
                {...register("proposals", { required: true })}
              ></textarea>
              <span className="conditions">
                Texto sin separaciones
                <Error error={errors.proposals} />
              </span>
            </div>
            <div>
              <button className="submitProposal">Cargar</button>
            </div>
          </form>
        </div>
      </FormProvider>
    </>
  );
};

export default UploadProposal;
