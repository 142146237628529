import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import '../careersPage.scss'

const NoInfoCareer = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className='careersPage'>
                <div className='careersPage_noJobs'>
                    <h3 className='careersPage_noJobs--title'> {t('careersPage.nothing.title')} </h3>
                    <p className='careersPage_noJobs--text'> {t('careersPage.nothing.description1')} </p>
                    <p className='careersPage_noJobs--text'> {t('careersPage.nothing.description2')} <mark> {t('careersPage.nothing.markdescription2')}</mark>! </p>
                    <p className='careersPage_noJobs--link'>{t('careersPage.nothing.start')}<NavLink to='/jobs/cv'>{t('careersPage.nothing.cv')}</NavLink>{t('careersPage.nothing.cvdescription')}</p>
                    <p className='careersPage_noJobs--link'>{t('careersPage.nothing.start')} <NavLink to='/joinNewsletter'>{t('careersPage.nothing.newsletter')}</NavLink> {t('careersPage.nothing.newsletterdescription')}</p>
                </div>
            </div>
        </>
    )
}

export default NoInfoCareer;