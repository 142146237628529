import React, { useState, useEffect } from "react";

// Components
import CardCareer from "features/careersPage/cardCareer/cardCareer";
import InfoCareer from "features/careersPage/infoCareer/infoCareer";
import TitleCareer from "features/careersPage/titleCareer/titleCareer";

import { Tabs, Tab, TabsList, TabPanel } from "@mui/base";
import { useAppSelector } from "Hooks/useRedux";
import { Tproposal } from "redux/proposalsSlice";

// Styles
import "./careersPage.scss";

const CareersPage = () => {
  const proposalsList: Array<Tproposal> = useAppSelector(
    (state) => state.proposals.proposalsList
  );
  const [isMobile, setMobile] = useState(window.innerWidth < 599);

  const [selectedCard, setSelectedCard] = useState(null);

  const updateMedia = () => {
    setMobile(window.innerWidth < 599);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const isCardSelected = (id: any) => {
    return selectedCard === id;
  };

  const handleCardClick = (id: any) => {
    if (selectedCard === id) {
      setSelectedCard(null);
    } else {
      setSelectedCard(id);
    }
  };

  return (
    <>
      {isMobile ? (
        <div data-testid="careersPage" className="careersPage">
          <TitleCareer />
          <div className="careersPage_jobs">
            <div className="careersPage_jobs--card">
              {proposalsList.map((val: any, index: number) => {
                const cardKey = `card-${val._id}-${index}`;
                const infoKey = `info-${val._id}-${index}`;
                return (
                  <div key={cardKey}>
                    <div
                      className={`cardCareer ${
                        isCardSelected(val._id) ? "selected" : ""
                      }`}
                      onClick={() => handleCardClick(val._id)}
                    >
                      <CardCareer
                        title={val.title}
                        location={val.location}
                        time={val.workModel}
                        postedOn={val.updatedAt}
                      />
                    </div>
                    {isCardSelected(val._id) && (
                      <div key={infoKey}>
                        <InfoCareer
                          title={val.title}
                          description={val.description}
                          workTask={val.workTasks}
                          workChallenge={val.workChallenges}
                          workCondition={val.workConditions}
                          proposal={val.proposals}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="careersPage" data-testid="careersPage">
          <TitleCareer />
          <Tabs
            className="careersPage_jobs"
            defaultValue={proposalsList[0]._id}
          >
            <TabsList className="careersPage_jobs--card">
              {proposalsList &&
                proposalsList.map((val: any, index: number) => {
                  return (
                    <Tab className="cardCareer" value={val._id} key={index}>
                      <CardCareer
                        title={val.title}
                        location={val.location}
                        time={val.workModel}
                        postedOn={val.updatedAt}
                      />
                    </Tab>
                  );
                })}
            </TabsList>
            <div className="careersPage_jobs--description">
              {proposalsList &&
                proposalsList.map((val: any, index: number) => {
                  return (
                    <TabPanel value={val._id} key={index}>
                      <InfoCareer
                        title={val.title}
                        description={val.description}
                        workTask={val.workTasks}
                        workChallenge={val.workChallenges}
                        workCondition={val.workConditions}
                        proposal={val.proposals}
                      />
                    </TabPanel>
                  );
                })}
            </div>
          </Tabs>
        </div>
      )}
    </>
  );
};

export default CareersPage;
