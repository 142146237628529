import { Link } from "react-router-dom";

import "./adminDetails.scss";
import CardProposal from "../cardProposal/cardProposal";
const AdminDetails = () => {
  return (
    <div className="adminHome">
      <h2>¡Hola!</h2>
      <button className="btn-adminHome">
        <Link to="/adminHome/uploadProposal">Nueva búsqueda</Link>
      </button>
      <h2>Búsquedas activas</h2>
      <div className="proposals">
        <CardProposal/>
      </div>
    </div>
  );
};

export default AdminDetails;
