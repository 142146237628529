import { useAppDispatch } from "Hooks/useRedux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { logout } from "redux/authSlice";

import "./adminNavbar.scss";

const AdminNavbar = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handlerLogout = () => {
    dispatch(logout());
  };
  return (
      <nav className="navbar">
        <ul className="adminNavbar">
          <li>
            <Link to="/" onClick={handlerLogout}>
              {t("header.logout")}
            </Link>
          </li>
        </ul>
      </nav>
  );
};

export default AdminNavbar;
