import React, { useEffect, useState } from "react";
import { ReactComponent as Trash } from 'assets/icons/icon-trash-proposal.svg';
import useGetApi from "Hooks/useGetApi";

import './cardProposal.scss';

import Loading from "components/loading";
import CardCareer from "features/careersPage/cardCareer/cardCareer";
import ModalComponent from "components/modal";

interface Proposal {
    _id: string;
    title: string;
    location: string;
    createdAt: string;
    workModel: string;
}

const CardProposal: React.FC = () => {
    const { data, isLoading } = useGetApi<Proposal[]>("api/workProposals/all");
    const [dataApi, setDataApi] = useState<Proposal[]>([]);

    const [selectedId, setSelectedId] = useState<String | null>(null);
    const [open, setOpen] = useState(Boolean);

    useEffect(() => {
        if (data) {
            setDataApi(data);
        }
    }, [data]);


    return (
        <>
            {isLoading ? (
                <div className='cardProposal_load'>
                    <Loading />
                </div>
            ) : dataApi.length > 0 ? (
                dataApi.map((val) => {
                    return (
                        <div className="cardProposal" key={val._id}>
                            <button
                                className="cardProposal-btn"

                                onClick={() => {
                                    setSelectedId(val._id);
                                    setOpen(true);
                                }}
                            >
                                <Trash fill="red" />
                            </button>
                            <CardCareer
                                title={val.title}
                                location={val.location}
                                time={val.workModel}
                                postedOn={val.createdAt}
                            />
                        </div>
                    );
                }
                )) : (
                <div>
                    <p>No hay datos</p>
                </div>
            )}
            {selectedId ? <ModalComponent open={open} setOpen={setOpen} id={selectedId} /> : null}
        </>
    );
};

export default CardProposal;
