
    const webPSupport = (): boolean => {
  
        const elem = document.createElement('canvas');
        if (elem.getContext && elem.getContext('2d')) {
          return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
        }
        return false;
      };
      
      const getImagePath = (webPPath: string, otherPath: string): string => {
        const supportsWebP =  webPSupport();
        return supportsWebP ? webPPath : otherPath;
      };

export  {webPSupport, getImagePath};