import React from 'react';

import './loading.scss';

const Loading = () => {
    return (
        <div className="body_loader">
            <span className="loader"></span>
        </div>
    );
};

export default Loading;
