import React, { createContext, useContext, useState } from 'react';

export interface SearchResult {
  content: string;
  elementRef: Element; 
}

interface SearchContextType {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  searchResults: SearchResult[];
  performSearch: (searchTerm: string) => void;
  navigateToResult: (result: SearchResult) => void;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

export function useSearch() {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearch debe ser usado dentro de un SearchProvider');
  }
  return context;
}

export function SearchProvider({ children }: { children: React.ReactNode }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);

  const navigateToResult = (result: SearchResult) => {
    const targetElement = result.elementRef;
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setSearchTerm(''); 
      setSearchResults([]); 
    }
  };

  const performSearch = (searchTerm: string) => {
     if (searchTerm === "") {
    setSearchResults([]);
    return;
  }
    const elementsWithText = Array.from(document.querySelectorAll('*:not(script):not(style)'));
    const matchingElements: SearchResult[] = [];

    for (const element of elementsWithText) {
      if (
        element.tagName !== "DIV" &&
        element.tagName !== "A" &&
        element.tagName !== "HEADER" &&
        element.tagName !== "NAV" &&
        element.tagName !== "UL" &&
        element.tagName !== 'LI' && 
        !element.classList.contains("header_search--button") &&
        !element.classList.contains("MuiSelect-root") &&
        !element.classList.contains("projectCard_box--category")
      ) {
        const elementText = normalize(element.textContent || "");
        if (!elementText.includes(".")) {
          if (elementText.includes(searchTerm)) {
            matchingElements.push({
              content: elementText.trim(),
              elementRef: element,
            });
          }
        }
      }
    }
    setSearchResults(matchingElements);
  };

  const normalize = (text: string) => {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  };

  return (
    <SearchContext.Provider value={{ searchTerm, setSearchTerm, searchResults, performSearch, navigateToResult }}>
      {children}
    </SearchContext.Provider>
  );
}
