import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import Header from 'features/header/header';
import Footer from 'features/footer/footer';
import { ProtectedRoutes } from 'services/protectedRoutes/protectedRoutes';
import AdminHome from 'features/Admin/adminHome/adminHome';
import RolsPage from 'features/rolsPage/rolsPage';
import ContainerCareersPage from 'features/careersPage/containerCareersPage';
import Loading from 'components/loading';

import 'styles/App.scss';

import { SearchProvider } from 'Context/searchContext';
const ScrollToTop = lazy(() => import('services/scrollToTopService'));
const CancelNewsletter = lazy(() => import('features/cancelNewsletterForm/cancelNewsletter'));
const JobForm = lazy(() => import('features/jobForm/jobForm'));
const JoinNewsletter = lazy(() => import('features/joinNewsletterForm/joinNewsletter'));
const Landing = lazy(() => import('features/landingPage/landing'));
const Legal = lazy(() => import('features/legal/legal'));
const Login = lazy(() => import('features/login/login'));


function App() {
  return (
    <div className="App">

<SearchProvider>
      <ScrollToTop />
      <Header />
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/privacy" element={<Legal />} />
          <Route path="/rols" element={<RolsPage />} />
          <Route path="/careers" element={<ContainerCareersPage />} />
          <Route path="/cancelNewsletter" element={<CancelNewsletter />} />
          <Route path="/joinNewsletter" element={<JoinNewsletter />} />
          <Route path="/jobs/:id" element={<JobForm />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Landing />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/adminHome/*" element={<AdminHome />} />
          </Route>
        </Routes>
      </Suspense>
      <Footer />
      </SearchProvider>
    </div>
  );
}

export default App;
