import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import Socials from "components/socials";

// Styles
import './footer.scss';

const Footer = () => {

  const { t } = useTranslation();
  const [isDesktop, setMobile] = useState(window.innerWidth > 900);

  const updateMedia = () => {
    setMobile(window.innerWidth > 900);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <>
      { isDesktop &&
        <div data-testid="footer" className="footer-neo">
          <NavLink to="/" className="footer-neo_name">Neocomplexx Group S.A</NavLink>
          <div className="footer-neo_terms">
          <NavLink to="/cancelNewsletter"><span>{t('footer.cancelNewsletter')}</span></NavLink>
            <NavLink to="/privacy"><span>{t('footer.terms')}</span></NavLink>
          </div>
          <div className="footer-neo_socials">
            <div className="footer-neo_socials--follow">
              <span className="footer-neo_socials--social">{t('footer.socials')}</span>
              <Socials height={"1.2rem"} justifyContent={"flex-end"} />
            </div>
            <span>{t('footer.created')}</span>
          </div>
        </div>
      }

      { !isDesktop &&
        <div data-testid="footer" className="footer-neo">
          <span className="mobile-footer">
            <NavLink to="/" className="footer-neo_name">Neocomplexx Group S.A</NavLink>
            <div className="footer-neo_terms">
              <NavLink to="/cancelNewsletter"><span>{t('footer.cancelNewsletter')}</span></NavLink>
              <NavLink to="/privacy"><span>{t('footer.terms')}</span></NavLink>
            </div>
            <span>{t('footer.created')}</span>
          </span>
          <div className="footer-neo_socials">
            <div className="footer-neo_socials--follow">
              <span>{t('footer.socials')}</span>
              <Socials height={"2.2rem"} justifyContent={"flex-end"} />
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default Footer;